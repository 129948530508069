.TEPanelTitleTitle {
}
.TESubNavbarUl .TESubNavbarLi {
	width: auto;
}
.TESubNavbarLink {
	padding: 0px 15px;
}

.TESideNavLinkLink {
	transition: color 0.2s ease-in, border 0.2s ease-in, background-color 0.2s ease-in;
}

.TESideNavLinkLink.active,
.TESideNavLinkLink:active,
.TESideNavLinkLink:hover {
	background-color: #e1e1e1;
}

.rt-tr-group .rt-tr.-odd{
	background-color: #E5E5E5;
}