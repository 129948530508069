html {
	position: relative;
	min-height: 100%;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	background-color: #f6f6f6;
	color: #5e5e5e;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.embed-container-home {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container-home iframe,
.embed-container-home object,
.embed-container-home embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/*
*
*
* TYPOGRAPHY
*
*/

/*
* Helvetica Neue
*/
@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-UltraLightItalic.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-UltraLightItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-UltraLight.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-UltraLight.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-Thin.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-Thin.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-ThinItalic.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-ThinItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-Light.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-LightItalic.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-Italic.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-Medium.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-MediumItalic.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-Bold.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-BoldItalic.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

/*Condensed*/
@font-face {
	font-family: 'Helvetica Neue Condensed';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-CondensedBold.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-CondensedBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue Condensed';
	src: url('./fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.woff2') format('woff2'),
		url('./fonts/HelveticaNeue/HelveticaNeue-CondensedBlack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #a0a0a0;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #a0a0a0;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: #a0a0a0;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #a0a0a0;
}
.logoImage .TEImageRowImageWrapper,
.logoImage .ecrozG {
	width: 96%;
	height: 250px;
	margin: 8px 19px;
}
.logoImage1 .ecrozG {
	width: 100%;
	height: 150px;
}

.estimatorimg {
	width: 100%;
	height: 150px;
	object-fit: contain;
	margin-bottom: 20px;
}
.estimatorimgemail {
	width: 100% !important;
	height: 350px !important;
	/* object-fit: contain; */
	margin-bottom: 20px;
}
.estimatorimg1 {
	width: 100%;
	height: 250px;
	/* object-fit: contain; */
	margin: 20px 0px;
}
.textuuuuuu .TEInputRowInput {
	text-align: center;
	font-weight: bold;
}
.termandcondition {
	margin-top: 20px;
}
.nnntext .TEInputRowInput {
	font-weight: bold;
}
/* */

/*
*
*
* REACT-TABLE-STYLES
*
*/
.bioData .TEDatetimeRow {
	width: 100%;
}
.companyPhone .uyyuyu {
	width: 100%;
}
.activeStatus {
	width: 120px;
}
.uyyuyu523 {
	width: 100%;
	padding: 6px;
	font-size: 18px;
	outline: none;
}
.ReactTable {
	border-radius: 5px;
	overflow: hidden;
}
.ReactTable .rt-tbody {
	overflow: hidden;
}
.ReactTable .rt-thead.-header,
.ReactTable .rt-thead.-headerGroups {
	background-color: #000000;
}

.ReactTable .rt-thead.-header .rt-th,
.ReactTable .rt-thead.-headerGroups .rt-th {
	color: white;
	padding: 10px 5px;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 13px;
		padding: 8px 5px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 12px;
	}
}
/* @media (max-width: 650px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		padding: 5px 5px;
	}
} */

.ReactTable .rt-thead.-filter .rt-th {
	color: white;
	padding: 10px 5px;
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 8px 5px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 5px;
	}
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	-webkit-box-shadow: inset 0 3px 0 0 white;
	box-shadow: inset 0 3px 0 0 white;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	-webkit-box-shadow: inset 0 -3px 0 0 white;
	box-shadow: inset 0 -3px 0 0 white;
}

.ReactTable .rt-thead .rt-th input[type='text'] {
	background-image: url(./images/searchIcon.png);
	background-position: calc(100% - 3px) center;
	background-repeat: no-repeat;
	background-size: auto calc(100% - 6px);
	padding: 5px;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	font-size: 16px;

	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th input[type='text']:hover {
	border-color: #000000;
	box-shadow: 0 0 0 1px #000000 inset;
}

.ReactTable .rt-tbody .rt-td {
	align-self: center;
	padding: 10px 5px;
	font-size: 14px;
	min-height: 30px;
}
@media (max-width: 800px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 8px 5px;
		font-size: 13px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 5px;
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th.right {
	text-align: right;
}

.ReactTable .rt-tbody .rt-td.actionCell {
	padding: 5px;
	white-space: normal;
	display: flex;
	flex-wrap: wrap;
}
.ReactTable .rt-tbody .rt-td.noPadding {
	padding: 0px;
}
.ReactTable .rt-tbody .rt-td.center {
	text-align: center;
}
.ReactTable .rt-tbody .rt-td.capitalize {
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.center-capitalize {
	text-align: center;
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.right {
	text-align: right;
}
.ReactTable .rt-tbody .rt-td.wrap {
	white-space: pre-line;
}

/*
*
* RECHARTS
*
*/
.recharts-tooltip-wrapper {
	z-index: 100000000;
}

/* my code nitesh  */

.modal {
	display: none;
	position: fixed;
	z-index: 999;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 90%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
	background-color: #fefefe;
	margin: auto;
	border: 1px solid #888;
	width: 70%;
	overflow: hidden;
}

.frm {
	padding: 20px;
}

.frm h2 {
	text-align: center;
	padding-bottom: 15px;
}

.form-control {
	width: 97%;
	padding: 10px;
	margin-bottom: 20px;
	margin-top: 5px;
}

.form-control:hover {
	border: 1px solid #000000;
	box-shadow: 0 0 0 1px #000000 inset;
}

.form-control:focus,
active {
	border: 1px solid #000000;
	box-shadow: 0 0 0 1px #000000 inset;
}

.modal-footer {
	padding: 0px;
}

.mainButton {
	width: 50%;
	float: left;
	border: none;
	border-top: 1px solid #000000;
	border-right: 1px solid #000000;
	border-radius: 0px;
	margin: 0px;
	border-bottom-left-radius: 15px;
	padding: 15px;
	background-color: #fff;
}

.mainButton:hover {
	color: #ffffff;
	background-color: #000000;
	border-top: 1px solid #000000;
	border-right: 1px solid #000000;
	border-bottom: none;
	border-left: none;
}

.frm .btn {
	width: 50%;
	float: left;
	border-top: 1px solid #000000;
	border-right: 1px solid #000000;
	border-radius: 0px;
	margin: 0px;
	border-bottom-left-radius: 15px;
	padding: 15px;
}

.frm .btn:hover {
	color: #ffffff;
	background-color: #000000;
	border-top: 1px solid #000000;
	border-right: 1px solid #000000;
	border-bottom: none;
	border-left: none;
}

.modal-content {
	border-radius: 15px;
}

label {
	color: #5e5e5e;
	font-size: 18px;
}

textarea {
	padding: 40px 0px !important;
}

.clickButton {
	background-color: black;
	border: none;
	outline: none;
	color: white;
	font-size: 20px;
	padding: 5px 20px;
	border-radius: 6px;
}

.snd {
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 0px;
}

#datatable th,
#datatable td {
	border: 1px solid #000;
	border-spacing: 0px;
	font-size: 1em;
}
#datatable {
	border-spacing: 0px;
	border: 1px solid #000;
	margin: 20px 0;
}

.exeledatasubmit {
	margin: -10px 0px 20px 0px !important;
}
.excelpage {
	margin: 40px 20px;
}
#qwerty {
	background: #fff;
}
#qwerty img {
	width: 80%;
}
.download-table-xls-button {
	background: transparent;
	border: none;
	width: 100%;
	padding: 0px;
	cursor: pointer;
	font-size: 17px;
	color: #5e5e5e;
}
.download-table-xls-button:hover {
	color: white;
}
.inputfileselect {
	padding: 20px 100px;
}
.inputfileselect:hover {
	color: white;
	cursor: pointer;
}
.flexbox-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}
/* rate page  */
.ratediv {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 10px;
	text-align: left;
	box-sizing: border-box;
	padding-right: 5px;
	width: 50%;
}

.ratediv label {
	position: relative;
	display: block;
	width: auto;
	box-sizing: border-box;
	margin-bottom: 3px;
	font-size: 18px;
	color: #5e5e5e;
	cursor: pointer;
}
.ratediv input {
	position: relative;
	display: block;
	width: 100%;
	font-size: 14px;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 5px;
	background-color: #ffffff;
	color: #5e5e5e;
	margin: 0px;
	border: 1px solid #e5e5e5;
	-webkit-transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}
.nitesh .TEPopupContent {
	margin: 20px 50px !important;
	max-width: 100% !important;
	margin: 100px auto !important;
}
.sumbitButton {
	margin-top: 30px !important;
}
.StatusField {
	margin-left: 70% !important;
}
.uyyuyu {
	margin-bottom: 0px !important;
	outline: none;
}
.uyyuyu:focus {
	outline: none;
}
.descriptiontable {
	width: 400px;
}
.uyyuyu .gKtked {
	outline: none !important;
	border: none !important;
}
.uyyuyu .gKtked:focus {
	outline: none !important;
	border: none !important;
}
textarea {
	padding: 0px 10px !important;
}
.SearchSchedule-wrapper {
	width: 100% !important;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.SearchSchedule-filter_zone {
	width: 76% !important;
	display: flex;
	flex-direction: column;
	margin: 25px 25px 25px 0px !important;
}
.SearchSchedule-wide {
	width: 100% !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 10px 5px 10px 0px !important;
}
.SearchSchedule-narrow {
	width: 48% !important;
}
.ResetFilters {
	width: 18% !important;
	margin: calc(21px + 25px) auto 25px auto !important;
}

.tickeSchedule > .TEPanelTitleBar {
	display: inline !important;
}

.estimate-table-row {
	position: relative;
}

.estimate-table-add-icon {
	padding: 4px;
	visibility: hidden;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-100%, -50%);
}

.estimate-table-remove-icon {
	padding: 4px;
	visibility: hidden;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(100%, -50%);
}

.estimate-table-row:hover .estimate-table-remove-icon,
.estimate-table-row:hover .estimate-table-add-icon {
	visibility: visible;
}

@media print {
	.print-estimate-sheet {
		color: black !important;
	}

	.print-estimate-sheet input {
		color: black !important;
	}

	.print-estimate-sheet input::placeholder {
		color: black !important;
	}

	.PowerSelect__TriggerLabel{
		color: black !important;
	}
}
